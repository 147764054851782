import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Prefooter = ({ issue }) => {
  const { articles } = useStaticQuery(graphql`
    query {
      articles: allContentfulArticle {
        nodes {
          author {
            name
          }
          issue {
            id
          }
        }
      }
    }
  `);

  function getIssueArticles(article) {
    if (article.author && article.issue?.[0].id === issue.id) {
      return true;
    }
  }

  const authors = [
    ...new Set(
      articles.nodes
        .filter(getIssueArticles)
        .map((article) => article.author.name)
    ),
  ];

  const team = issue.team;

  return (
    <footer className="prefooter">
      <div className="container">
        <h2>
          Spot Magazine n<sup>o</sup> {issue.number} – {issue.title}
        </h2>
        <div className="prefooter-grid">
          {team && (
            <div className="prefooter-grid-item">
              {team.representative && (
                <p>
                  <strong>
                    {team.representative.gender === "Femme"
                      ? "Représentante légale"
                      : "Représentant légal"}
                    {" :"}
                  </strong>
                  <br />
                  {team.representative.name}
                </p>
              )}
              {team.director && (
                <p>
                  <strong>
                    {team.director.gender === "Femme"
                      ? "Directrice de la publication"
                      : "Directeur de la publication"}
                    {" :"}
                  </strong>
                  <br />
                  {team.director.name}
                </p>
              )}
              {team.editor && (
                <p>
                  <strong>
                    {team.editor.gender === "Femme"
                      ? "Rédactrice en chef"
                      : "Rédacteur en chef"}
                    {" :"}
                  </strong>
                  <br />
                  {team.editor.name}
                </p>
              )}
            </div>
          )}
          <div className="prefooter-grid-item">
            {authors && (
              <p>
                <strong>Auteur·e·s :</strong>
                <br />
                {authors.map((author, index) => (
                  <React.Fragment key={author}>
                    {author}
                    {authors.length !== index + 1 && ", "}
                  </React.Fragment>
                ))}
              </p>
            )}
            {team?.subscription && (
              <p>
                <strong>Abonnement :</strong>
                <br />
                {team.subscription.subscription}
              </p>
            )}
          </div>
          {team?.credits && (
            <div className="prefooter-grid-item">
              <p>
                <strong>Crédits photos :</strong>
                <br />
                {team.credits.credits}
              </p>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Prefooter;
