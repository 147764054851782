import * as React from "react";

const Next = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Suivant</title>
    <rect width="40" height="40" rx="20" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5857 20.0001L16.2928 14.7072L17.7071 13.293L24.4142 20.0001L17.7071 26.7072L16.2928 25.293L21.5857 20.0001Z"
      fill="white"
    />
  </svg>
);

export default Next;
