import * as React from "react";

const Prev = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Précédent</title>
    <rect width="40" height="40" rx="20" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4142 20.0001L23.7071 14.7072L22.2929 13.293L15.5858 20.0001L22.2929 26.7072L23.7071 25.293L18.4142 20.0001Z"
      fill="white"
    />
  </svg>
);

export default Prev;
