import * as React from "react";
import SwiperCore, { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import ArticleCard from "./article-card";
import Prev from "./prev";
import Next from "./next";

SwiperCore.use([A11y, Navigation]);

const SectionCarousel = ({ articles }) => {
  const prevEl = React.useRef(null);
  const nextEl = React.useRef(null);

  return (
    <div className="section-carousel">
      <button ref={prevEl} className="section-carousel-prev">
        <Prev />
      </button>
      <button ref={nextEl} className="section-carousel-next">
        <Next />
      </button>
      <Swiper
        spaceBetween={16}
        slidesPerView="auto"
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevEl.current;
          swiper.params.navigation.nextEl = nextEl.current;
        }}
        breakpoints={{
          768: {
            spaceBetween: 40,
            slidesPerView: "auto",
          },
          1024: {
            spaceBetween: 64,
            slidesPerView: 3,
          },
        }}
      >
        {articles.map((article) => (
          <SwiperSlide key={article.id}>
            <ArticleCard article={article} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SectionCarousel;
