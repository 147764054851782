import * as React from "react";
import SwiperCore, { A11y, Navigation } from "swiper";
import "swiper/css";

import SectionHeading from "./section-heading";
import SectionCarousel from "./section-carousel";

SwiperCore.use([A11y, Navigation]);

const Section = ({ title, articles, className }) => {
  if (!articles) return null;

  return (
    <section className={`section${className ? ` ${className}` : ""}`}>
      <SectionHeading title={title} />
      <SectionCarousel articles={articles} />
    </section>
  );
};

export default Section;
