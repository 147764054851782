import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Hero = ({ issue }) => (
  <header className="hero">
    <div className="container">
      <div className="hero-text">
        <h1>{issue.title}</h1>
        <p className="eyebrow">
          N<sup>o</sup> {issue.number} – {issue.dateRange}
        </p>
        {issue.editorial && (
          <Link className="button" to={`/articles/${issue.editorial.slug}/`}>
            Lire l’édito
          </Link>
        )}
      </div>
      {issue.heroImage && (
        <figure className="hero-image">
          <GatsbyImage
            image={issue.heroImage.localFile.childImageSharp.gatsbyImageData}
            alt={issue.heroImage.description || ""}
            style={{ height: "100%" }}
          />
        </figure>
      )}
    </div>
  </header>
);

export default Hero;
