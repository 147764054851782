import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Hero from "../../components/hero";
import Section from "../../components/section";
import Prefooter from "../../components/prefooter";

const IssueTemplate = ({ data }) => {
  const issue = data.issue;

  return (
    <Layout issue={issue}>
      <Seo
        title={issue.title}
        image={issue.heroImage}
        color={issue.color}
        bodyClass="issue"
      />
      <Hero issue={issue} />
      <div className="container">
        <Section
          title="Dossier"
          articles={issue.dossier}
          className="section-dossier"
        />
        <Section
          title="Voyager"
          articles={issue.travel}
          className="section-travel"
        />
        <Section title="Dans votre CSE" articles={issue.news} />
        <Section title="Découvrir" articles={issue.discover} />
        <Section title="Se divertir" articles={issue.revel} />
        <Section
          title="Sur un coin de l’étagère"
          articles={issue.mediaLibrary}
        />
      </div>
      <Prefooter issue={issue} />
    </Layout>
  );
};

export default IssueTemplate;

export const query = graphql`
  query ($slug: String) {
    issue: contentfulIssue(slug: { eq: $slug }) {
      ...issueFields
      heroImage {
        description
        file {
          details {
            image {
              width
              height
            }
          }
        }
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 80)
          }
        }
      }
      editorial {
        ...articleFields
      }
    }
  }
`;
